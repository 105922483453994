import {connect} from "react-redux";
import React from "react";
import {setTokenExpired} from "../../../redux/actions/authActions";
import {setNotificationMessage} from "../../../redux/actions/notificationActions";
import {createRegionListAction} from "../../../redux/action-creators/regionActionCreator";
import {createTimezoneListAction} from "../../../redux/action-creators/timezoneActionCreator";
import {regionsFetched} from "../../../redux/actions/regionActions";
import { createCityListAction } from '../../../redux/action-creators/cityActionCreator';
/**
 * HOC(Higher order component which fetches regions and updated redux store and passses regions as props to returned component
 * @param WrappedComponent
 * @returns {{new(*=): {render: (function()), componentDidMount: (function()), handleChange: (function()), componentWillUnmount: (function())}}}
 */
// This function takes a component...
function withSubscription(WrappedComponent) {
    // ...and returns another component...
    return class extends React.Component {
        componentDidMount() {
            if (!this.props.regions) {
                this.fetchRegions();
                this.props.fetchCities();
            }
            if(!this.props.timezones){
                this.timeZoneRegions();
            }
        }

        render() {
            // ... and renders the wrapped component with the fresh data!
            // Notice that we pass through any additional props
            return <WrappedComponent {...this.props}/>;
        }

        /**
         * fetches regions and updates the state
         */
        fetchRegions() {
            this.props.fetchRegions();
        }
        timeZoneRegions(){
            this.props.timeZoneRegions();
        }

        fetchCities(){
            this.props.fetchCities();
        }
    };
}

export default  (WrappedComponent, mapStateToProps = (state)=>({}), mapDispatchToProps = (dispatch)=>({})) => {
    const updatedMapStateToProps = state=> {
        return Object.assign({}, mapStateToProps(state), {regions: state.Regions.regions,timezones:state.Timezones.timezones});
    };
    const updatedMapDispatchToProps = dispatch=> {
        return Object.assign({}, mapDispatchToProps(dispatch), {
            setTokenExpired: value=> {
                dispatch(setTokenExpired(value))
            },
            fetchRegions: ()=> {
                dispatch(createRegionListAction())
            },
            timeZoneRegions :()=>{
                dispatch(createTimezoneListAction())
            },
            regionFetched: regions=> {
                dispatch(regionsFetched(regions))
            },
            initiateNotification: value=> {
                dispatch(setNotificationMessage(value))
            },
            fetchCities:()=>{
                dispatch(createCityListAction())
            }
        });
    };
    return connect(updatedMapStateToProps, updatedMapDispatchToProps)(withSubscription(WrappedComponent));
}