import { actions } from "./action-list";

export function citiesFetched(value) {
  return {
      type: actions.CITIES.DATA_FETCHED,
      value: value
  }
}

export function changeCity(value) {
  return {
    type: actions.CHANGE_CITY,
    value: value,
  };
}
export function changeDepot(value) {
  return {
    type: actions.CHANGE_DEPOT,
    value: value,
  };
}
