import {actions} from "../../actions/action-list";
import {filterData} from "../../../shared/utils";

function Regions(state = {}, action) {
    let newState;
    switch (action.type) {
        case actions.REGIONS.DATA_FETCHED:
            let regions = action.value.sort((a, b)=> {
                if (a.regionId < b.regionId) {
                    return -1;
                }
                if (a.regionId > b.regionId) {
                    return 1;
                }
                return 0;
            });
            regions = [regions[0],{regionId: 'N/A', name: 'UnMaped Region'}, ...regions.slice(1)];
            return Object.assign({}, state, {
                regions: regions,
                displayData: regions
            });
        case actions.REGIONS.SEARCH:
            if (state.regions) {
                let data;
                if (state.search && action.value.startsWith(state.search)) {
                    data = state.filteredData
                } else {
                    data = state.regions;
                }
                let filteredData = filterData(data, 'regionId', action.value);
                newState = Object.assign({}, state, {
                    filteredData: filteredData,
                    displayData: filteredData,
                    search: action.value,
                })
            } else {
                newState = Object.assign({}, state, {
                    search: action.value
                })
            }
            return newState;
        default:
            return state;
    }
}
export default Regions;