import { MessageType } from "../../Constant";
import { getCityData } from "../../services/vehicleMasterService";
import { setTokenExpired } from "../actions/authActions";
import { setNotificationMessage } from "../actions/notificationActions";
import { citiesFetched } from "../actions/cityAction";
export function createCityListAction() {
  return (dispatch) => {
    getCityData()
      .then((response) => {
        dispatch(citiesFetched(response.content));
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          dispatch(setTokenExpired(true));
        } else {
          if (err.status != 204)
            dispatch(
              setNotificationMessage({
                type: MessageType.ERROR,
                message: err.description
                  ? err.description
                  : "Error while fetching cities",
              })
            );
        }
      });
  };
}
