import React from "react";
import classNames from "classnames";
/**
 * Used to show protocol list in row in List view
 * @param item Object of item to display in list view
 * @param rest Object of item to display in list view
 */
export const RegionListItem = ({ item, ...rest }) => {
  return (
    <tr key={item.regionId} className="gradeA odd" role="row">
      <td className="list-item">{item.regionId}</td>
      <td>{item.deploymentPort ? item.deploymentPort : "NA"}</td>
      <td>
        <span className="protocol">{item.endpoint ? item.endpoint : "NA"}</span>
        <div className="protocol-list">
          {item.endpoint ? item.endpoint : "NA"}
        </div>
      </td>
      <td>{item.remoteHost ? item.remoteHost : "NA"}</td>
      <td>{item.remotePort ? item.remotePort : "NA"}</td>
      <td>
        <span
          className={classNames({
            "red-class": item.parsing_Enabled == false,
            "green-class": item.parsing_Enabled == true,
          })}
        >
          <a>
            {item.parsing_Enabled && (
              <i className="fa fa-check" aria-hidden="true"></i>
            )}
            {!item.parsing_Enabled && (
              <i className="fa fa-times" aria-hidden="true"></i>
            )}
          </a>
        </span>
      </td>
      <td>
        <span className="protocol">
          {item.protocols && item.protocols.map((protocol, index) => {
            return (
              <span className="list-item" key={protocol.protocolId}>
                {" "}
                {protocol.protocolId}{" "}
                {item.protocols.length > index + 1 ? "," : ""}
              </span>
            );
          })}
        </span>
        <ul className="protocol-list">
          {item.protocols && item.protocols.map((protocol) => {
            return (
              <li className="list-item" key={protocol.protocolId}>
                {protocol.protocolId}
              </li>
            );
          })}
        </ul>
      </td>
      <td>
        {item.tags && item.tags.length > 0? 
          <>
        <span className="protocol">
          {item.tags.map((tag)=>tag.tag).join(',')}
        </span>
        <ul className="protocol-list">
          {item.tags.map((tag) => {
            return (
              <li className="list-item" key={tag.id}>
                {tag.tag}
              </li>
            );
          })}
        </ul>
         </> : "NA" }
      </td>
      <td className="action-column">
        {rest.showEdit && (
          <span className="table-icons edit-icon">
            <a
              onClick={() => {
                rest.onOpenClick();
              }}
            >
              <span className="edit-view-icon" />
              <span className="protocol-list">edit</span>
            </a>
          </span>
        )}
      </td>
    </tr>
  );
};
