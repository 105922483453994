export const actions = {
    CHANGE_TIME_PARSE: "CHANGE_TIME_PARSE",
    CHANGE_TIME_INTERVAL: "CHANGE_TIME_INTERVAL",
    CHANGE_DEVICE_REGION: "CHANGE_DEVICE_REGION",
    CHANGE_CITY: "CHANGE_CITY",
    CHANGE_DEPOT: "CHANGE_DEPOT",
    CHANGE_PROTOCOL: "CHANGE_PROTOCOL",
    CHANGE_STATUS: "CHANGE_STATUS",
    CHANGE_SORT: "CHANGE_SORT",
    CHANGE_WARNING_FILTER: "CHANGE_WARNING_FILTER",
    EXPORT_DEVICES: "EXPORT_DEVICES",
    REGIONS_FETCHED: "REGIONS_FETCHED",
    UPDATE_AUTHORITIES: "UPDATE_AUTHORITIES",
    TOKEN_EXPIRE: "TOKEN_EXPIRE",
    NOTIFICATION: "NOTIFICATION",
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
    LOAD: "LOAD_USER",
    SERVER_TIME: "SERVER_TIME",
    USERS_FETCHED: "USERS_FETCHED",
    USERS_LOADING: "USERS_LOADING",
    LOG_OUT: 'LOG_OUT',
    TOKEN_REFRESHED:'TOKEN_REFRESHED',
    FETCHED_CITY_META: 'FETCHED_CITY_META',
    DELAY_MATRIX: {
        DATA_FETCHED: Symbol('DATA_FETCHED'),
        CHANGE_SORT: 'CHANGE_SORT',
        SEARCH: Symbol('SEARCH')
    },
    REGIONS: {
        DATA_FETCHED: Symbol('DATA_FETCHED'),
        SEARCH: Symbol('SEARCH')
    },
    TIMEZONE:{
        DATA_FETCHED: Symbol('DATA_FETCHED'),
    },
    PROTOCOLS: {
        DATA_FETCHED: Symbol('DATA_FETCHED'),
        LOADING: Symbol('LOADING')
    },
    POSTING_ERROR: {
        DATA_FETCHED: Symbol('DATA_FETCHED'),
        LOADING: Symbol('LOADING'),
    },
    NOTIFICATION_REGION:"NOTIFICATION_REGION",
    SET_SELECTED_PANIC_ALERT_ROW:"SET_SELECTED_PANIC_ALERT_ROW",
    CITIES: {
        DATA_FETCHED: Symbol('DATA_FETCHED'),
        SEARCH: Symbol('SEARCH')
    },
};