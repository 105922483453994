import React from "react";
import {ListItem} from "../list-item/reportListItem";
import {TableWrapper} from "../../../../../shared/layout";
import {TableLoader} from "../../../../../shared/widgets";
import {
    reportsType,
    distanceDetailTemplateStrings,
    gpsQualityDetailTemplateStrings,
    distanceTemplateStrings,
    gpsQualityTemplateStrings
} from "../../utils";
export const ListView = (props)=> {
    console.log("props content",props.content)

   let templateStrings;
  
   switch (props.type) {
       case reportsType.DISTANCE:
           templateStrings = distanceTemplateStrings;
           if(!Object(JSON.parse(localStorage.getItem('authorities'))).Admin){
            delete templateStrings["odmDistance"]
           }
           break;
       case reportsType.GPS_QUALITY:
           templateStrings = gpsQualityTemplateStrings;
           break;
       default:
           if (props.detailType === reportsType.DISTANCE) {
               templateStrings = distanceDetailTemplateStrings;
           } else if (props.detailType === reportsType.GPS_QUALITY) {
               templateStrings = gpsQualityDetailTemplateStrings;
           } else {
               templateStrings = {};
           }
           break;
   }

    return (
        <TableWrapper id="report-list">
            <TableWrapper.Header columns={templateStrings} onSortToggle={props.onSortChange}/>
            {props.loading && <TableLoader loading={props.loading}
                                           colSpan={Object.keys(templateStrings).length}/>}
            {!props.loading && <tbody>
            {props.content && props.content.map((data, index) => {
                return <ListItem key={index} item={data} columns={templateStrings} navigateTo={props.navigateTo}
                                 keyId="vehicleNo" keyId2="deviceId"/>;
            })}
            {(!props.content || (props.content && props.content.length == 0)) &&
            <tr className="gradeA odd noDataStyle" role="row">
              <td className="sorting_1" colSpan="6">
                No Data Found!
              </td>
            </tr>
            }
            </tbody>}
        </TableWrapper>)
};