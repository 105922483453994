import React,{createRef} from "react";
import MenuList from "./Menu-List";
import "./sidebar.css";
import addNotification,{ Notifications } from 'react-push-notification';
import NotificationSound from '../../App/notification.mp3';
import { getPanicReportNotification } from '../../services/vehicleMasterService';
import { connect } from "react-redux";
import { setNofificationRegion } from "../../redux/actions/notificationActions";
import { AuthenticationService } from "../../services/authorization";
import { Authorities } from '../../services/authorization/accessRights';
class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalId:null
        }
        this.audioPlayer = createRef();
    }

    playAudio = (response) => {
        addNotification({
            title: 'Warning',
            subtitle: 'This is a subtitle',
            message: 'There is a new alert on panic report',
            theme: 'darkblue',
            native: true, 
            duration:30000,
            onClick: () => { 
                this.props.updateNotificationRegion(response.regionId);
                this.props.moveToPanicReport()
             }
        });
        this.audioPlayer.current.play();
      }

      componentDidMount() {
        localStorage.setItem("previousAlertTimestamp",0);
        const authInstance = AuthenticationService.getInstance()
        if(!authInstance.Authorities[Authorities.BSD]){
            const intervalId = setInterval(() => {
                getPanicReportNotification()
                .then((response)=>{
                    if(localStorage.getItem("previousAlertTimestamp") > 0 && response.newAlertStartFrom){
                        this.playAudio(response);
                    }
                    if(response.newAlertStartFrom){
                        localStorage.setItem("previousAlertTimestamp",response.newAlertStartFrom)
                    }
                })
                .catch((error)=>{
                    console.log(error);
                })
            }, 30000);
            this.setState({  intervalId });
            
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    render() {
        return (
            <div id="wrapper" className="content" onMouseOver={this.props.SideBarShow} onMouseLeave={this.props.SideBarDontShow}>
                <audio ref={this.audioPlayer} src={NotificationSound} />
                <Notifications position='bottom-middle' />
                <div className="logo">
                        <span className="small-nav-handle"
                            onClick={this.props.toggle}>
                       <div className="icon-container">
                                    <span className="sidebar-icon menu-icon"/>
                                </div>
                    </span>
                    <span className="logo-icon">
                        </span>
                </div>
                <MenuList toggleShow={this.props.toggleShow} SideBarDontShow={this.props.SideBarDontShow} toggle={this.props.toggle} location={this.props.location}/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNotificationRegion: value => {
            dispatch(setNofificationRegion(value))
        }
    }
};

export default connect(null,mapDispatchToProps)(SideBar);